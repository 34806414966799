import React from 'react'

import { Title, IntlValue } from '../../../components'

import './style.css'

export function VendorPromotions({vendor: {promotions}}) {

  if (!(promotions?.length > 0)) return null

  return(
    <div id="vendor-promotions">
      {promotions.map((promotion, key) => {
        if (promotion?.active) return(
          <div key={key} className="promotions-title">
            <i className="fas fa-badge-percent"></i>
            <div className="info">
              <Title><IntlValue>{promotion.label}</IntlValue></Title>
            </div>
          </div>
        )

        return null
      }
      )}
    </div>
  )
}
