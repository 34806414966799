import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const PARTNERS_GRID = gql`
  query partnersGrid($offset: ConnectionArgs!, $closedOffset: ConnectionArgs!){
    featuredNodes: paginatedVendorList(type: FEATURED, connectionArgs: {first: 50}){
      edges{
        cursor
        node{
          ...VENDOR_FRAGMENT
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }

    promotionNodes: paginatedVendorList(type: PROMOTION, connectionArgs: {first: 50}){
      edges{
        cursor
        node{
          ...VENDOR_FRAGMENT
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }

    newNodes: paginatedVendorList(type: NEW, connectionArgs: {first: 50}){
      edges{
        cursor
        node{
          ...VENDOR_FRAGMENT
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }

    allOpenNodes: paginatedVendorList(type: ALL_OPEN, connectionArgs: $offset){
      edges{
        cursor
        node{
          ...VENDOR_FRAGMENT
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }

    allClosedNodes: paginatedVendorList(type: ALL_CLOSED, connectionArgs: $closedOffset){
      edges{
        cursor
        node{
          ...VENDOR_FRAGMENT
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VENDOR_FRAGMENT}
`
