import React from 'react'
import ReactModal from 'react-modal'

import { CloseButton } from '../../components'

import './style.css'

export function Modalv3({id = "", fluid, header, children, footer, disableClose, contentStyle, modal = {}, padding}){

  if (!modal.opened) return null

  const style = {
    content : {
      left                  : '50%',
      top                   : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      display               : 'inline-block',
      margin                : '0 auto',
      padding               : '0',
      border                : 'none',
      borderRadius          : '5px',
      transform             : 'translate(-50%, 100%)',
      background            : 'transparent'
    }    
  }

  return(
    <ReactModal isOpen={modal.opened} style={style} onRequestClose={!disableClose ? modal.hide : null} appElement={document.getElementById('root')}>
      <div id={`${id}-modal`} className={`modalv3 ${fluid && "full"} ${padding && "padding"}`}>
        <div className="modal-info">
          {header &&
            <div className="modal-header">
              {header}
              {!disableClose && <CloseButton onClick={modal.hide}/>}
            </div>
          }
          <div className="modal-content" style={contentStyle}>
            {children}
          </div>
          {footer &&
            <div className="modal-footer">
              {footer}
            </div>
          }
        </div>
      </div>
    </ReactModal>
  )
}
