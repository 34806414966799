import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { CREATE_ADDRESS_MUTATION, MY_ADDRESSES_QUERY } from '../../../graphql'
import { useCart, useIntl, useValidatedForm } from '../../../hooks'
import { toast } from '../../../helpers'
import { Button, LabeledInput, LabeledTextarea, RadioDot, IntlText, Validation, Notificaion } from '../../../components'
import { HelpBox } from '../../../views'

import './style.css'

export function NewAddressForm({location}) {

  const validations = {
    name: [{id: "required", validation: (val) => !!val}],
    building: [{id: "required", validation: (val) => !!val}],
  }
  const navigate = useNavigate()
  const [showHelp, toggleHelp] = useState(false)
  const [createAddress] = useMutation(CREATE_ADDRESS_MUTATION)
  const {form: { type, name, building, unit, instructions }, validate, errors, updateField} = useValidatedForm({type: "APARTEMENT"}, validations)
  const { getTranslation } = useIntl()
  const { set } = useCart()
  
  async function submit(){
    
    const alert = toast.loading()
    try {
      const data = {
        name,
        type,
        building,
        unit,
        instructions,
        location: {type: "Point", coordinates: [location?.lng, location?.lat]},
      }
  
      const { valid } = validate()
  
      if (!valid) throw new Error(getTranslation({id: "form-error"}))      

      const { data: { createAddress: loc } } = await createAddress({variables: {data}, refetchQueries: [{query: MY_ADDRESSES_QUERY}]})
      set("deliveryLocation", loc)
      navigate('/delivery-service')
      alert.success(getTranslation({group: "address-form", id: "save-success"}))
    } catch (e) {
      toggleHelp(true)
      alert.apolloError(e)
    }    
  }
  
  function goBack(){
    navigate('../')
  }

  return(
    <div id="address-form" className={Object.keys(errors).length > 0 ? "error" : ""}>
      {!(location?.lat && location?.lng) && 
        <Notificaion icon='location-circle' type="error" title={<IntlText group="address-form" id="location-error" />}>
          <span><IntlText group="address-form" id="location-error-info" /></span>
        </Notificaion>
      }
      <div id="property-types">
        <div className="property-type" onClick={() => updateField({key: "type", value: "APARTEMENT"})}>
          <RadioDot checked={type === "APARTEMENT"} readOnly/>
          <FontAwesome name="building" />
          <span><IntlText group="address-form" id="APARTEMENT" /></span>
        </div>
        <div className="property-type" onClick={() => updateField({key: "type", value: "PRIVATEHOUSE"})}>
          <RadioDot checked={type === "PRIVATEHOUSE"} readOnly/>
          <FontAwesome name="home" />
          <span><IntlText group="address-form" id="PRIVATEHOUSE" /></span>
        </div>
      </div>
      <div className="location-fields-wrapper">
        <div className="location-fields">
          <Validation errors={errors.name} >
            <LabeledInput name="name" placeholder={getTranslation({group: "address-form", id: `input-name-${type}`})} value={name} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
          <Validation errors={errors.building}>
            <LabeledInput name="building" placeholder={getTranslation({group: "address-form", id: `input-building-${type}`})} value={building} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
          <LabeledInput name="unit" placeholder={getTranslation({group: "address-form", id: `input-unit-${type}`})} value={unit} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
        </div>
        <div className="location-fields">
          <LabeledTextarea name="instructions" placeholder={getTranslation({group: "address-form", id: `input-instructions`})} value={instructions} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          <span className="help"><IntlText group="address-form" id="help" /></span>
          <div className="actions">
            <Button onClick={goBack}><IntlText id="back" /></Button>
            <Button theme="main" onClick={submit}><IntlText group="address-form" id="save" /></Button>
          </div>
          {showHelp &&
            <HelpBox />
          }
        </div>
      </div>
    </div>
  )
}
