import React from 'react'

import './MapPin.css'

export default function MapPin({id, className, onClick, children, tooltip}) {

  return (
    <div id={id} className={`map-pin ${className}`} onClick={onClick}>
      {children}
      {tooltip &&
        <div className="tooltip">
          {tooltip}
        </div>
      }
    </div>
  )
}
