import React from 'react'

import { PendingPurchases, PurchaseHistory, OrderHelpModal, ReportProblemModal, OrderModal } from '../../views'

import './style.css'

export function Orders() {
  return(
    <div id="orders" className="container" >
      <div className="row wrapped no-margin" style={{paddingTop: 40, paddingBottom: 40}}>
        <div id="orders-wrapper">
          <div className="content">
            <PendingPurchases />
            <PurchaseHistory />
          </div>
        </div>
      </div>
      <OrderHelpModal />
      <OrderModal />
      <ReportProblemModal />
    </div>
  )
}
