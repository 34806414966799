import { useIntl } from '../../../hooks'
import { Filters, IntlText } from '../../../components'

import './style.css'

export function VendorFilters({params, removeParam, updateQuery}){

  const { getTranslation } = useIntl()

  return(
    <>
      <Filters.Button id="mustAcceptCard" onClick={() => updateQuery({mustAcceptCard: {value: true, id: "mustAcceptCard", }})} reset={() => removeParam("mustAcceptCard")} active={!!params?.mustAcceptCard?.value}><IntlText group="menu-filter" id="online-payment" /></Filters.Button>
      <Filters.Button id="highRating" onClick={() => updateQuery({highRating: {value: true, id: "highRating"}})} reset={() => removeParam("highRating")} active={!!params?.highRating?.value}><IntlText group="menu-filter" id="high-rating" /></Filters.Button>
      <Filters.Button id="onlyOpen" onClick={() => updateQuery({onlyOpen: {value: true, id: "onlyOpen"}})} reset={() => removeParam("onlyOpen")} active={!!params?.onlyOpen?.value}><IntlText group="menu-filter" id="only-open" /></Filters.Button>      
      <Filters.Select hideArrow value={!!params?.sortBy?.value ? getTranslation({group: "menu-filter", id: params?.sortBy?.value}) : ""} placeholder={getTranslation({group: "menu-filter", id: "sort-by"})} icon="sort-amount-down" reset={() => removeParam("sortBy")}>
        <Filters.SelectItem active={params?.sortBy?.value === "RELEVANCE"} onClick={() => updateQuery({sortBy: {value: "RELEVANCE", id: "sortBy"}})}>
          <IntlText group="menu-filter" id="relevance" />
        </Filters.SelectItem>
        <Filters.SelectItem active={params?.sortBy?.value === "PERCENTAGE_OF_POSITIVE"} onClick={() => updateQuery({sortBy: {value: "PERCENTAGE_OF_POSITIVE", id: "sortBy"}})}>
          <IntlText group="menu-filter" id="rating" />
        </Filters.SelectItem>
        <Filters.SelectItem onClick={() => removeParam("sortBy")}>
          <IntlText id="reset" />
        </Filters.SelectItem>              
      </Filters.Select>       
    </>
  )

}
