import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Map, MapPin, IntlText } from '../../components'

import './OrderAddress.css'

export default function OrderAddress({purchase: {address}}) {
  return(
    <div className="order-address">
      <div className="info-table">
        <div className="info-table-item">
          <div className="label"><FontAwesome name="address-card" /><IntlText group="order-address" id="name">Name</IntlText></div>
          <div className="info">{address.name}</div>
        </div>
        <div className="info-table-item">
          <div className="label"><FontAwesome name="building" /><IntlText group="order-address" id="building">Building</IntlText></div>
          <div className="info">{address.building}</div>
        </div>
        <div className="info-table-item">
          <div className="label"><FontAwesome name="map-marker" /><IntlText group="order-address" id="unit">Unit</IntlText></div>
          <div className="info">{address.unit}</div>
        </div>
        <div className="info-table-item">
          <div className="label"><FontAwesome name="sticky-note" /><IntlText group="order-address" id="instructions">Instructions</IntlText></div>
          <div className="info">{address.instructions}</div>
        </div>
      </div>
      <div className="map">
        <Map center={{lng: address.location.coordinates[0], lat: address.location.coordinates[1]}}>
          <MapPin lng={address.location.coordinates[0]} lat={address.location.coordinates[1]}><FontAwesome name="map-marker" /></MapPin>
        </Map>
      </div>
    </div>
  )
}
