import React from 'react'
import FontAwesome from 'react-fontawesome'

import { useEditPurchase, useIntl } from '../../../../hooks'
import { formatPrice } from '../../../../helpers'
import { Loader, Toggle } from '../../../../components'

export function UseBalance({purchaseQuote}){

    const { getTranslation } = useIntl()
    const { editPurchaseQuote, loading } = useEditPurchase({})

  if (purchaseQuote?.customerBalance <= 0 || purchaseQuote?.payment !== "CASH") return null

  async function toggleUseCredit(){
    await editPurchaseQuote({useBalance: !purchaseQuote?.useBalance})
  }

  return (
    <div id="use-balance" className="checkout-option">
      <Loader loading={loading} theme='main' overlay />
      <span className='icon'><FontAwesome name='dollar-sign' style={{color: "#42a942", fontSize: "var(--font-l)"}} /></span>
      <div className="info">
        <div className="texts">
          <div className="desc"></div>
          <div>{getTranslation({group: "cod-options", id: "use-balance", variables: {amount: formatPrice(purchaseQuote?.customerBalance)}})}</div>
        </div>
        <Toggle id="use-balance-switch" checked={purchaseQuote?.useBalance} onChange={toggleUseCredit} />
      </div>
    </div>       
  )
}