import React, { useState, useLayoutEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { toast } from '../../helpers'
import { Button } from '../'

import './style.css'

export function Text({placeholder, type = "textarea", children, save, actions, icon, disabled, validation}) {

  const [edit, toggleEdit] = useState(false)
  const [value, setValue] = useState(children)
  const element = useRef(null)

  function _save(){
    try {
      if (validation?.validate && !validation.validate(value)) return toast.error(validation.error)
      save(value)
      toggleEdit(false)
    } catch (error) {
      console.log("Error", error);
    }
  }

  function autogrow(){
    if (!element?.current) return null
    element.current.style.height = "0"
    element.current.style.height = element.current.scrollHeight - 2 +"px"
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  function reset(){
    setValue(children)
    toggleEdit(false)
  }

  useLayoutEffect(() => {
    if (type === "textarea") autogrow()
  })

  return(
    <div className={`edit-box ${value ? "populated" : ""} ${edit ? "edit" : ""}`}>
      {icon && showIcon()}
      <span className="value-text">
        <span className="label">{placeholder}</span>
        {type === "textarea" ? 
          <textarea ref={element} className="value-input" value={value || ""} onChange={edit ? (e) => setValue(e.target.value) : null} disabled={!edit}/>
          :
          <input type={type} className="value-input" value={value || ""} onChange={edit ? (e) => setValue(e.target.value) : null} disabled={!edit}/>
        }
      </span>
      {!disabled && save &&
        <div className="actions-wrapper">
            {edit ?
              <div className="actions">
                <Button icon="save" theme="main" onClick={_save}/>
                <Button icon="times" theme="alt" onClick={reset}/>
                {actions}
              </div>
            :
              <div className="actions">
                <Button icon="edit" theme="alt" onClick={() => toggleEdit(true)}/>
                {actions}
              </div>
            }
        </div>
      }
    </div>
  )

}

const editbox = { Text }

export default editbox