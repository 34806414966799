import React from 'react'

import { formatPrice } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function PurchaseTotals({children, purchase}) {

  function inCurrency(_value){
    return `${_value} ${purchase.currency}`
  }

  let totals = [
    {label: <IntlText group="order-totals" id="subtotal" />, value: purchase?.price?.subtotal},
    {label: <IntlText group="order-totals" id="serviceFeeCustomer" />, value: purchase?.price?.serviceFeeCustomer},
    {label: <IntlText group="order-totals" id="discount" />, value: purchase?.price?.discount},
    {label: <IntlText group="order-totals" id="grossTip" />, value: purchase?.price?.grossTip},
    {label: <IntlText group="order-totals" id="deliveryFeeTotal" />, value: purchase?.price?.deliveryFeeTotal, show: true},
    {label: <IntlText group="order-totals" id="transactionFee" />, value: purchase?.price?.transactionFee},
    {label: <IntlText group="order-totals" id="tax" />, value: purchase?.price?.tax},
    {label: <IntlText group="order-totals" id="balance" />, value: purchase?.price?.customerCashBalanceAdjustment},
    {slug: "total", label: <IntlText group="order-totals" id="total" />, value: purchase?.payment === "CASH" ? purchase?.price?.chargeInCash : purchase?.price?.chargeOnCard, show: true},
  ]

  if (purchase?.currency !== "MXN") totals.push({label: <IntlText group="order-totals" id="totalInCurrency" />, value: purchase?.payment === "CASH" ? purchase?.price?.cashChargeInCurrency : purchase?.price?.totalInCurrency, show: true, format: inCurrency},)

  return(
    <div id="purchase-totals">
      <div className="order-notficiations">
        {children}
      </div>
      <table className="totals-table">
        <tbody>
          {totals.map((total, key) => {
            if (!!total.value || total.show)
              return(
                <tr key={key} className={`purchase-total ${total.slug || ""}`}>
                  <td>{total.label}</td>
                  <td>{total?.format ? total.format(total.value) : formatPrice(total.value)}</td>
                </tr>
              )

            return null
          })}
        </tbody>
      </table>
    </div>
  )
}
