import { gql } from '@apollo/client'

import { PURCHASE_PRICE_FRAGMENT } from '../purchase'

export const PURCHASE_QUOTE_FRAGMENT = gql`
  fragment PURCHASE_QUOTE_FRAGMENT on PurchaseQuote {
    id
    deliveryDistance
    deliveryAvailable
    deliveryUnavailableReason
    isTomatoOpen
    isVendorOpen
    vendorClosesInSeconds
    customerBalance
    promotions{
      originalId
      owner
      name
      description{
        en
        es
      }
      label{
        en
        es
      }
    }
    currencyPaymentAvailable
    cashPaymentAvailable
    conektaPaymentAvailable
    mercadopagoPaymentAvailable
    fullBalancePaymentAvailable
    networkTransactionsDisabledForVendor
    stripePaymentAvailable
    stripeClientSecret
    stripePaymentId
    unavailableProducts
    unavailableAddonOptions    
    freeDeliveryIsApplicable
    customerHasCel
    isVerifiedCustomer
    forcedCelVerification
    forcedCelVerificationReason
    price{
      ...PURCHASE_PRICE_FRAGMENT
    }
    savedCards{
      id
      card{
        brand
        exp_month
        exp_year
        last4
      }
      created
      canBeUsed
      hasExpired
    }
    customer
    status
    payment
    currency
    flow
    customerNote
    vendor
    useBalance
    sendUtensils
    contactPreference
    banknote
    riderTipPercentage
  }
  ${PURCHASE_PRICE_FRAGMENT}
`
