import { Navigate } from 'react-router-dom'

import { usePurchase } from '../../hooks'
import { Title, MediaQuery, IntlText } from '../../components'
import { CheckoutSummary, PaymentMethods, AddUtensils, CustomerConfirm, CashPaymentOptions,  } from '../../views'

import './style.css'

export function Checkout() {

  const { cart: { state: {items, deliveryLocation} }, loading, error, purchaseQuote, refetch  } = usePurchase({})

  if (items.length > 0 && deliveryLocation) return(
    <div id="checkout" className="container">
      <div className="row wrapped">
        <div className="checkout-content">
          <div className="checkout-steps">
            <div id="customer" className="checkout-step">
              <div className="heading">
                <Title tag="h2"><IntlText group="checkout" id="customer-title" /></Title>
                <div className="desc"><IntlText group="checkout" id="customer-desc" /></div>
              </div>
              <div className="checkout-step-content">
                <CustomerConfirm />
              </div>
            </div>
            <div id="payment" className="checkout-step">
              <div className="heading">
                <Title tag="h2"><IntlText group="checkout" id="payment-title" /></Title>
                <div className="desc"><IntlText group="checkout" id="payment-desc" /></div>
              </div>
              <div className="checkout-step-content">
                <PaymentMethods purchaseQuote={purchaseQuote} />
              </div>
            </div>
            <div id='checkout-options'>
              {"CASH" === purchaseQuote?.payment &&
                <CashPaymentOptions purchaseQuote={purchaseQuote} />
              }            
              <AddUtensils purchaseQuote={purchaseQuote} />
            </div>
            <MediaQuery max={1279}>
              <div id="summary" className="checkout-step">
                <div className="heading">
                  <Title tag="h2"><IntlText group="checkout" id="total-title" /></Title>
                  <div className="desc"><IntlText group="checkout" id="total-desc" /></div>
                </div>
                <div className="checkout-step-content">
                  <CheckoutSummary loading={loading} error={error} refetch={refetch} purchaseQuote={purchaseQuote} />
                </div>
              </div>
            </MediaQuery>
          </div>
          <MediaQuery min={1279}>
            <CheckoutSummary loading={loading} error={error} refetch={refetch} purchaseQuote={purchaseQuote} />
          </MediaQuery>
        </div>
      </div>
    </div>
  )

  return <Navigate to={{ pathname: "/delivery-service"}} />

}
