import React, { useContext } from 'react'

import { IntlContext } from '../../../contexts'
import { useEditPurchase } from '../../../hooks'
import { IntlText, EditBox, Select, Loader } from '../../../components'

import './style.css'

export function PurchaseDeliveryInfo({purchaseQuote}){
  
  const { getTranslation } = useContext(IntlContext)
  const { editPurchaseQuote, loading } = useEditPurchase({})
  const contactOptions = [
    {id: "WHATSAPP", label: getTranslation({group: "order-info", id: "WHATSAPP"})},
    {id: "PHONECALL", label: getTranslation({group: "order-info", id: "PHONECALL"})},
    {id: "DOORBELL", label: getTranslation({group: "order-info", id: "DOORBELL"})},
  ]

  return(
    <div id='purchase-delivery-info'>
      <Loader theme='main' loading={loading} overlay />
      <Select id="contactPreference" selected={contactOptions.find(option => option.id === purchaseQuote?.contactPreference)} defaultValue={<IntlText group="place-purchase" id="nothing-selected" />} placeholder={<IntlText group="place-purchase" id="contact-preference" />} options={contactOptions} onChange={(selected) => editPurchaseQuote({contactPreference: selected?.id})} />
      <div>
        <EditBox.Text placeholder={<div><IntlText group="place-purchase" id="instructions" /></div>} save={(value) => editPurchaseQuote({customerNote: value})}>{purchaseQuote?.customerNote}</EditBox.Text>        
        {!!purchaseQuote?.customerNote &&
          <div className='warning'>
            <span>{getTranslation({group: "place-purchase", id: "note"})}</span>
          </div>
        }
      </div>
    </div>
  )
}
