import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import FontAwesome from 'react-fontawesome'

import { getImageInSize } from '../../helpers'
import { useModal } from '../../hooks'
import { PURCHASE_QUERY } from '../../graphql'
import { ModalContext } from '../../contexts'
import { Title, CloseButton, IntlText, Loader } from '../../components'
import { PurchaseProducts, PurchaseTotals, PurchaseInfo, OrderAddress, OrderInvoices, PurchaseRevisions } from '../../views'

import style from '../../style/modal'
import './OrderModal.css'

export default function OrderModal() {

  const [selectedTab, changeTab] = useState("order-info")
  const { isOpened, closeModal, attributes } = useContext(ModalContext)
  const { data, loading } = useQuery(PURCHASE_QUERY, {variables: {id: attributes?.purchase?.id}, skip: !attributes?.purchase?.id})

  if (isOpened("orderModal")) return(
    <Modal isOpen={isOpened("orderModal")} style={style} onRequestClose={() => closeModal("orderModal")} appElement={document.getElementById('root')}>
      <div id="order-modal" className='modal'>
        {loading ? <Loader /> : <Inner selectedTab={selectedTab} changeTab={changeTab} closeModal={closeModal} purchase={data?.purchase} />}
      </div>
    </Modal>
  )

  return null
}

function Inner({closeModal, purchase, changeTab, selectedTab}){

  const modal = useModal()
  
  if (!purchase) return null


  return(
    <div className="order-modal-info modal-info">
      <CloseButton onClick={() => closeModal("orderModal")}/>
      <div className="order-modal-header modal-header">
        <Link className="partner-box" to={`/${purchase.vendor.slug}`}>
          <div className="partner-logo">
            <div>
              <picture>
                <source srcSet={getImageInSize(purchase.vendor.logo, "150", "webp")} type="image/webp" />
                <source srcSet={getImageInSize(purchase.vendor.logo, "150", "png")} type="image/png" />
                <img src={purchase.vendor.logo} alt={purchase.vendor.name} title={purchase.vendor.name}/>
              </picture>                   
            </div>
          </div>
          <div className="partner-name">
            <Title tag="h2">{purchase.vendor.name}</Title>
          </div>
        </Link>
        <div className="tabs-menu">
          <div className={`tabs-menu-item ${selectedTab === "order-info" ? "active" : ""}`} onClick={() => changeTab("order-info")}>
            <span><IntlText group="order-modal" id="info" /></span>
          </div>
          <div className={`tabs-menu-item ${selectedTab === "order-items" ? "active" : ""}`} onClick={() => changeTab("order-items")}>
            <span><IntlText group="order-modal" id="items" /></span>
          </div>
          <div className={`tabs-menu-item ${selectedTab === "order-address" ? "active" : ""}`} onClick={() => changeTab("order-address")}>
            <span><IntlText group="order-modal" id="address" /></span>
          </div>
          {purchase.revisions?.length > 0 &&
            <div className={`tabs-menu-item ${selectedTab === "order-history" ? "active" : ""}`} onClick={() => changeTab("order-history")}>
              <span><IntlText group="order-modal" id="history" /></span>
            </div>
          }
          <div className={`tabs-menu-item ${selectedTab === "order-invoices" ? "active" : ""}`} onClick={() => changeTab("order-invoices")}>
            <span><IntlText group="order-modal" id="invoice" /></span>
          </div>
        </div>
      </div>
      <div className="order-modal-content">
        <div className="tabs-wrapper">
          <div className="tabs">
            {selectedTab === "order-info" &&
              <div className="tab active">
                <PurchaseInfo purchase={purchase} />
              </div>
            }
            {selectedTab === "order-items" &&
              <div className="tab active">
                <PurchaseProducts purchase={purchase} />
                <PurchaseTotals purchase={purchase}>
                  {purchase.revisions?.length > 0 &&
                    <div id="revisions" className="totals-info">
                      <div className="icon">
                        <FontAwesome name="exclamation-triangle"/>
                      </div>
                      <div className="info" onClick={() => modal.show(purchase)}>
                        <IntlText group="order-totals" id="purchase-history"/>
                      </div>
                    </div>              
                  }                     
                </PurchaseTotals>
              </div>
            }
            {selectedTab === "order-address" &&
              <div className="tab active">
                <OrderAddress purchase={purchase} />
              </div>
            }
            {selectedTab === "order-invoices" &&
              <div className="tab active">
                <OrderInvoices purchase={purchase} />
              </div>
            }
            {selectedTab === "order-history" &&
              <div style={{overflow: "auto"}} className="tab active">
                <PurchaseRevisions purchase={purchase} />        
              </div>
            }
          </div>
        </div>
      </div>
    </div>  
  )
}
