import React, { useContext } from 'react'
import { Query } from '@apollo/client/react/components'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useQueryBuilder } from '../../hooks'
import { getImageInSize } from '../../helpers'
import { IntlContext } from '../../contexts'
import { PAGE, PRODUCT_LIST_QUERY } from '../../graphql'
import { Title, MediaQuery, IntlValue } from '../../components'
import { Menu, CategoryNavigation, MenuSkeleton, ClosedLabel, VendorTags, OpeningHours, DeliveryFee, VendorRating, ProductTagsFilter, VendorPromotions } from '../../views'
import { Page, NotFound } from '../../containers'

import './style.css'

export function Partner() {

  const { slug } = useParams()
  const { loading, data, error} = useQuery(PAGE, { variables: {slug}})

  if (loading) return(
    <div id="single-partner" className="container skeleton">
      <div className="partner-header">
        <div className="row wrapped">
          <div className="partner-logo-wrapper">
            <div className="skeleton-thumb"/>
            <div className="line" />
          </div>
        </div>
      </div>
      <div className="row wrapped no-margin">
        <MenuSkeleton />
      </div>
    </div>
  )

  if ((!data?.vendor && !data?.page) || error) return <NotFound />

  return (
    <>
      <Inner vendor={data?.vendor} />
      <Page page={data?.page} />
    </>
  )

}

function Inner({vendor}){

  const location = useLocation()
  const { getTranslation } = useContext(IntlContext)
  const query = useQueryBuilder({
    data: {
      // query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")},
      // tags: {key: "tags", label: `Search for ${search.get("tags")}`, value: search.get("tags")}
    }
  })
  
  if (!vendor) return null

  return(
    <div id="single-partner" className={`container ${vendor.wallpaper || (vendor.wallpaper && vendor.contract === "FREEDELIVERY") ? "featured" : vendor.contract.toLowerCase()}`}>
      <Helmet>
        <title>{getTranslation({group: "meta", id: "partner-title", variables: {vendor: vendor.name}})}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={`${getTranslation({group: "meta", id: "partner-description", variables: {vendor: vendor.name}})} ${vendor.productTypes ? getTranslation({group: "meta", id: "partner-description-extra", variables: {products: vendor.productTypes.join(", ")}}) : ""}`} />
        <meta property="og:title" content={getTranslation({group: "meta", id: "partner-title", variables: {vendor: vendor.name}})} />
        <meta property="og:description" content={`${getTranslation({group: "meta", id: "partner-description", variables: {vendor: vendor.name}})} ${vendor.productTypes ? getTranslation({group: "meta", id: "partner-description-extra", variables: {products: vendor.productTypes.join(", ")}}) : ""}`} />
        <meta property="og:url" content={`${window.location.protocol}//${window.location.host + location.pathname}`} />
        <link rel="canonical" href={`${window.location.protocol}//${window.location.host}/${vendor.slug}`} />
      </Helmet>
      {(vendor.wallpaper || (vendor.wallpaper && vendor.contract === "FREEDELIVERY")) &&
        <div className="row">
          <MediaQuery min={900}>
            <div className="featured-background" style={{backgroundImage: `url(${getImageInSize(vendor.wallpaper, "1920", "jpeg")})`}}></div>
          </MediaQuery>
          <MediaQuery max={900}>
            <div className="featured-background" style={{backgroundImage: `url(${getImageInSize(vendor.cover, "1200", "jpeg")})`}}></div>
          </MediaQuery>
        </div>
      }
      <div className="partner-header">
        <div className="row wrapped no-margin">
          <div className="partner-logo-wrapper">
            <div className="partner-logo">
              <picture>
                <source srcSet={getImageInSize(vendor.logo, "150", "webp")} type="image/webp" />
                <source srcSet={getImageInSize(vendor.logo, "150", "png")} type="image/png" />
                <img src={vendor.logo} alt={vendor.name} title={vendor.name} />
              </picture>   
            </div>
            <div className="partner-title">
              <Title>{vendor.name}</Title>
              <div className="desc">{vendor.intro && <IntlValue>{vendor.intro}</IntlValue>}</div>
              <ClosedLabel style={{marginTop: 5}} vendor={vendor}/>
            </div>
          </div>
          <div className="info-bar">
            <DeliveryFee vendor={vendor} />
            <VendorRating vendor={vendor} />
            <OpeningHours vendor={vendor} />
            <VendorTags vendor={vendor} />
          </div>
        </div>
      </div>
      <Query query={PRODUCT_LIST_QUERY} variables={{vendor: vendor.id}}>
        {({ loading, error, data }) => {
          if (loading) return (
            <div className="row wrapped">
              <MenuSkeleton />
            </div>
          )

          if (error) return `Error! ${error.message}`

          const { productList } = data

          return(
            <div className="inner">
              <div className="category-list-wrapper">
                <div className="row wrapped no-margin">
                  <div className="category-list">
                    <CategoryNavigation navigationItems={productList}/>
                  </div>
                </div>
              </div>
              <MediaQuery max={1200}>
                <div className='row wrapped no-margin' style={{marginTop: 10}}>
                  <VendorPromotions vendor={vendor} />
                </div>
                <ProductTagsFilter padded {...query} />
              </MediaQuery>                                    
              <div className="row wrapped no-margin" style={{marginBottom: 0}}>
                <div id="single-partner-content">                         
                  <Menu categories={productList} vendor={vendor} query={query}>
                    <MediaQuery min={1200}>
                      <div style={{marginBottom: 10}}>
                        <VendorPromotions vendor={vendor} />
                      </div>
                      <ProductTagsFilter {...query} />
                    </MediaQuery>   
                  </Menu>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    </div>
  )
}