import React from 'react'

import { IntlText } from '../../../components'

import './style.css'

const statusOptions = [
  {status: "processing", active: ["INQUEUE", "REQUIRES_PAYMENT", "REQUIRES_CONFIRMATION"], visited: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]},
  {status: "received", active: ["PROCESSED"], visited: ["INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]},
  {status: "inProgress", active: ["INPREPARATION", "READYFORCOLLECTION"], visited: ["ONTHEWAY", "ATLOCATION", "COMPLETED"]},
  {status: "onTheWay", active: ["ONTHEWAY"], visited: ["ATLOCATION", "COMPLETED"]},
  {status: "arrived", active: ["ATLOCATION"], visited: ["COMPLETED"]},
  {status: "delivered", active: ["COMPLETED"]},
]

export function PurchaseProgressBar({purchase: {status}}) {
  const isActive = (status, active = [], visited = []) => {
    if (active.includes(status)) return "active"

    if (visited.includes(status)) return "visited"

    return null
  }

  return(
    <div className="checkout-wrap">
      <ul className={`checkout-bar ${isActive(status, statusOptions[statusOptions.length-1].active) ? "last-active" : ""}`}>
        {statusOptions.map((statusOption, key) =>
          <li key={key} className={`${isActive(status, statusOption.active, statusOption.visited)}`}><IntlText group="progress-bar" id={`status-option-${statusOption.status}`} /></li>
        )}
      </ul>
    </div>
  )
}
