import React from 'react'
import moment from 'moment'

import { Title, IntlText, Timer } from '../../../components'

import './style.css'

export function PurchaseStatusText({purchase: {status, preparationTime, riderConfirmed, arrivedAtVendor, timestamps: {vendorConfirmed}}}) {

  switch (status) {
    case "REQUIRES_PAYMENT":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="requires-payment" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="requires-payment-desc" /></Title>
        </div>
      )

    case "REQUIRES_CONFIRMATION":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="requires-confirmation" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="requires-confirmation-desc" /></Title>
        </div>
      )

    case "INQUEUE":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="inqueue" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="inqueue-desc" variables={{time: preparationTime, when: moment(vendorConfirmed).format("HH:mm")}} /></Title>
        </div>
      )
    case "INPREPARATION":
    case "READYFORCOLLECTION":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="inpreparation" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="inpreparation-desc" variables={{time: preparationTime, when: moment(vendorConfirmed).format("HH:mm")}} /></Title>
          <Timer style={{justifyContent: "center"}} time={preparationTime * 60} start={vendorConfirmed}>
            {arrivedAtVendor ?
              <IntlText group="order-status-text" id="arrived-at-vendor" />
            : riderConfirmed ?
              <IntlText group="order-status-text" id="rider-confirmed" />
            :
              <IntlText group="order-status-text" id="ready-for-collect" />
            }
          </Timer>
        </div>
      )

    case "ONTHEWAY":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="ontheway" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="ontheway-desc" /></Title>
        </div>
      )

    case "ATLOCATION":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="atlocation" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="atlocation-desc" /></Title>
        </div>
      )

    case "COMPLETED":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="completed" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="completed-desc" /></Title>
        </div>
      )

    case "CANCELLED":
    case "REJECTED":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="cancelled" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="cancelled-desc" /></Title>
        </div>
      )

    case "DELIVERYFAILED":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="delivery-failed" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="delivery-failed-desc" /></Title>
        </div>
      )

    case "UNPROCESSED":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="unprocessed" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="unprocessed-desc" /></Title>
        </div>
      )

    case "PAYMENTFAILED":
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="payment-failed" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="payment-failed-desc" /></Title>
        </div>
      )

    default:
      return(
        <div className="order-status-text">
          <Title tag="h2"><IntlText group="order-status-text" id="default" /></Title>
          <Title tag="h3"><IntlText group="order-status-text" id="default-desc" /></Title>
        </div>
    )
  }
}
