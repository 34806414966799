import React from 'react'
import FontAwesome from 'react-fontawesome'
import { AsYouType } from 'libphonenumber-js'

import { useCart, useModal, useAuth } from '../../../hooks'
import { IntlText, Loader, Button, Map, MapPin, Link } from '../../../components'
import { SelectLocationModal } from '../../../views'

import './style.css'

export function CustomerConfirm(){

  const { state: { deliveryLocation }} = useCart()
  const { loading, user, error } = useAuth()
  const modal = useModal()

  if (loading || error) return <Loader />

  return(
    <div className="checkout-customer">
      <div className='selected-map'>
        <div className='map-actions'>
          <Button onClick={() => modal.show()}><IntlText group="customer-confirm" id="change-location" /></Button>
        </div>
        <Map center={{lng: deliveryLocation.location.coordinates[0], lat: deliveryLocation.location.coordinates[1]}} options={{draggable: false, gestureHandling: false}}>
          <MapPin lng={deliveryLocation.location.coordinates[0]} lat={deliveryLocation.location.coordinates[1]} {...deliveryLocation.location.coordinates}><FontAwesome name="map-marker" /></MapPin>
        </Map>      
      </div>
      <table>
        <tbody>
          <tr>
            <td><FontAwesome name="address-card" /><IntlText id="input-name" /></td>
            <td>{user?.name}</td>
          </tr>
          <tr>
            <td><FontAwesome name="envelope" /><IntlText id="input-email" /></td>
            <td>{user?.email}</td>
          </tr>
          <tr>
            <td><FontAwesome name="phone" /><IntlText id="input-phone" /></td>
            <td>{user?.cel && `+${user?.cel.phoneCode} ${new AsYouType(user?.cel?.countryCode).input(user?.cel?.phoneNumber)}`}</td>
          </tr>
        </tbody>
      </table>
      <Link to="/account" button>
        <IntlText group="customer-confirm" id="edit-profile" />
      </Link>
      <SelectLocationModal modal={modal} />
    </div>
  )
}
