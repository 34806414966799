import FontAwesome from 'react-fontawesome'
import { useLocation } from 'react-router-dom'

import { useModal, useCart, useAuth } from '../../hooks'
import { SlideInButton, MediaQuery, Logo, IntlText, Link } from '../../components'
import { SelectLocationModal, SearchBox } from '../../views'

import './style.css'

export function Header() {

  const location = useLocation()
  const modal = useModal()
  const { state } = useCart()
  const { user } = useAuth()
  // const { getTranslation } = useIntl()

  return(
    <div id='header-wrapper'>
      <div id="header">
        <div className="main-nav block">
          <SlideInButton id="left">
            <FontAwesome name="bars"/>
          </SlideInButton>
          <MediaQuery min="680">
            <div className='separator'>
              <Logo />
              {/*
                <SegmentedControl rounded value={state.type} options={[{id: "ONDEMAND", label: getTranslation({group: "purchase", id: "ONDEMAND"})}, {id: "CUSTOMER_PICKUP", label: getTranslation({group: "purchase", id: "CUSTOMER_PICKUP"})}]} onClick={(_option) => set("type", _option.id)}/>          
              */}
            </div>
          </MediaQuery>
          <MediaQuery max="680">
            <div className='separator'>
              <Logo appIcon />
            </div>
          </MediaQuery>
          {user && 
            <MediaQuery min="800">
              <div className='separator'>
                <div className='select-location-button' onClick={() => modal.show({})}>
                  <FontAwesome name="map-marker-alt"/>
                  {state?.deliveryLocation ? 
                    <div className='selected-location'>
                      <span className='name'>{state?.deliveryLocation?.name}</span>
                      {/* <span className='zone'>{state?.deliveryLocation?.zone}</span> */}
                    </div>
                  :
                    <span><IntlText group='header' id="select-location" /></span>
                  }
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><path fill="var(--ui-text)" d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"></path></svg>
                </div>
              </div>
            </MediaQuery>
          }
          <SearchBox />          
          <SlideInButton id="cart" className='cart-button'>
            <FontAwesome name="shopping-cart" />
            <MediaQuery min="550">
                {state?.items?.length > 0 &&
                  <span className='qty'>{state?.items?.length}</span>
                }
            </MediaQuery>
          </SlideInButton>        
          <div className='separator'>
            {user ?
              <SlideInButton id="right">
                <FontAwesome name="user" />
                    <div>
                      <MediaQuery min="1023">
                        <div className="user-welcome">
                          <span className="greeting"><IntlText group="header" id="greetings" /></span>
                          <span className="name">{user?.name}</span>
                        </div>
                      </MediaQuery>
                    </div>
              </SlideInButton>
            :
              <div className='auth-buttons'>
                <Link to='/login' button><IntlText group='login-form' id='login-button' /></Link>
                <Link to='/register' button theme='main'><IntlText group='register-form' id='register-button' /></Link>
              </div>
            }
          </div>
        </div>
      </div>
      {user && (location?.pathname === "/delivery-service" || location?.pathname === "/checkout" || location?.pathname === "/search") &&
        <MediaQuery max="800">      
          <div className='header-mobil-location'>
            <div className='select-location-button' onClick={() => modal.show({})}>
              <FontAwesome name="map-marker-alt"/>
              {state?.deliveryLocation ? 
                <div className='selected-location'>
                  <span className='name'>{state?.deliveryLocation?.name}</span>
                  {/* <span className='zone'>{state?.deliveryLocation?.zone}</span> */}
                </div>
              :
                <span><IntlText group='header' id="select-location" /></span>
              }
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><path fill="var(--ui-text)" d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"></path></svg>
            </div>        
          </div>      
        </MediaQuery>
      }
      <SelectLocationModal modal={modal} />
    </div>
  )
}
