import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Modalv3, Title } from '../'

import './style.css'

export function SelectModal({id, modal, selected, options, placeholder, onChange, labelId = "label"}) {

  return(
    <Modalv3 id="bills" modal={modal} header={<Title tag="h3">{placeholder}</Title>} padding>
      <div id={id} className={`select-modal`}>
          <ul>
            {options.map((option, key) =>
              <SelectItem key={key} selected={option.id === selected?.id} onChange={onChange} option={option} labelId={labelId} />
              )}
          </ul>
      </div>
    </Modalv3>       
  )
}

function SelectItem({selected, option, onChange, labelId}){

  function _onChange(){
    onChange(option)
  }

  return(
    <li className={`${selected ? "selected" : ""}`} onClick={_onChange}>
      <span>{option[labelId]}</span>
      {selected &&
        <span className='icon'><FontAwesome name="check" /></span>
      }
    </li>
  )
}