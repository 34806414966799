import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { usePlatformDetect } from '../../hooks'
import { copyToClipboard, getAsset } from '../../helpers'
import { IntlText, Button, Logo, CloseButton } from '../../components'

import './style.css'

export function AppDownload() {

  const platform = usePlatformDetect()

  if (platform !== 'ios' && platform !== "android") return null
  return <PopUp platform={platform} />

}

function PopUp({platform}){

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const referralCode = search?.get("referral")  
  const [showed, toggleShowed] = useState(localStorage.getItem("app-download-showed") && !referralCode)  
  const icons = getAsset("icons.json")

  useEffect(() => {

    if (!showed){
      document.body.classList.add("mobile-install-open")
    }

    return () => {
      document.body.classList.remove("mobile-install-open")
    }
    // eslint-disable-next-line
  }, [])  

  function close(){
    document.body.classList.remove("mobile-install-open")
    localStorage.setItem('app-download-showed', true)
    toggleShowed(true)

    if (referralCode){
      copyToClipboard(JSON.stringify([{"tomatoReferralCode": referralCode}]))
      search.delete("referral")
      navigate({search: search.toString(), replace: true})
    }
  }

  function openLink(){
    close()
    if (platform === "ios"){
      window.location.replace(`https://apps.apple.com/mx/app/tomato-mx/id${process.env.REACT_APP_APPSTORE_ID}`)    
    } else if (platform === "android"){
      window.location.replace(`https://play.google.com/store/apps/details?id=${process.env.REACT_APP_PLAYSTORE_ID}`)    
    }
  }  

  if (showed) return null
  
  return(
    <div id="app-download">
      <div className='inner-header'>
        <Logo />
        <CloseButton onClick={close} />
      </div>
      <div className='inner'>
        <div className='text'>
          <h2><IntlText group="home" id="download-app" /></h2>
          <IntlText group="home" id="download-app-desc" />
        </div>
        <div className='image'><img alt='Download app' src={icons.tryTheApp} /></div>
        <div className='buttons'>
          <Button theme='main' onClick={openLink}><IntlText group="home" id="continue-with-app" /></Button>
          <span className='link' onClick={close}><IntlText group="home" id="use-the-web" /></span>      
        </div>
      </div>
    </div>
  )
}