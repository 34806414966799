import { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { PURCHASE_QUOTE_QUERY, MY_PURCHASE_QUOTE_UPDATED_SUBSCRIPTION } from '../graphql'
import { CartContext } from '../contexts'

export function usePurchase({toggleLoading = () => null}){

    const cart = useContext(CartContext)
    const { state, prepareItems, processPurchaseQuote, set } = cart
    const { loading, error, data, refetch, networkStatus, subscribeToMore } = useQuery(PURCHASE_QUOTE_QUERY, {
        variables:{
          data: {
            flow: "V1",
            vendor: state?.vendor?.id,
            address: state?.deliveryLocation?.id,
            // type: state?.type,
            isMobileApp: false,
            products: prepareItems(),
           }
    }, fetchPolicy: "network-only", nextFetchPolicy: 'cache-first', onCompleted, onError: (error) => {
        if (error.message === "Address does not exist"){
            set("deliveryLocation", undefined)
        }
    }})    

    useEffect(() => {
        const myPurchaseQuoteUpdated = subscribeToMore({document: MY_PURCHASE_QUOTE_UPDATED_SUBSCRIPTION})        

        return () => {
            myPurchaseQuoteUpdated()
        }
    // eslint-disable-next-line 
    }, [])

    function onCompleted(data){
        toggleLoading(false)
        processPurchaseQuote(data?.purchaseQuote)
    }

    return{
        cart,
        refetch,
        loading,
        networkStatus,
        purchaseQuote: data?.purchaseQuote,
        error,
    }
}