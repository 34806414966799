import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { CartContext, IntlContext } from '../../../contexts'
import { formatPrice } from '../../../helpers'
import { IntlText, Tooltip, IntlValue } from '../../../components'
import { DeliveryFeeDetails } from '../../../views'

import './style.css'

export function PurchaseSummary({purchaseQuote}){

  const { calculateTotals} = useContext(CartContext)
  const { getTranslation } = useContext(IntlContext)
  const totals = calculateTotals()

  const {
    deliveryDistance,
    promotions,
    payment,
    price: {
      deliveryFeeTotal,
      deliveryFeeBase,
      deliveryFeeDistance,
      transactionFee,
      serviceFeeForCustomer,
      grossTip,
      tax,
      subtotal,
      currency,
      chargeOnCard,
      chargeInCash,
      cashChargeInCurrency,
      applicableExchangeRate,
      customerCashBalanceAdjustment
    },
  } = purchaseQuote

  return(
    <div id="purchase-summary">
      <PurchasePromotions promotions={promotions} />
      <div className="summary-row">
        <span><IntlText group="place-purchase" id="subtotal" /></span>
        <span className="qty">({totals.qty} <IntlText id="items" />)</span>
        <span className="price">{formatPrice(subtotal, true)}</span>
      </div>
      {payment === "CARD_STRIPE" && 
        <div className="summary-row rider-tip-row">
          <Tooltip text={<IntlText group="place-purchase" id="tip-info" />} right>
            <span><IntlText group="place-purchase" id="tip" /><FontAwesome name="info-circle" /></span>
          </Tooltip>
          <span className="price">{formatPrice(grossTip, true)}</span>
        </div>
      }
      {serviceFeeForCustomer > 0 && 
        <div className="summary-row">
          <span><IntlText group="place-purchase" id="service-fee-for-customer" /></span>
          <span className="price">{formatPrice(serviceFeeForCustomer, true)}</span>
        </div>
      }
      <div className="summary-row delivery-fee-row">
        <Tooltip text={<DeliveryFeeDetails fees={{deliveryDistance, deliveryFeeBase, deliveryFeeDistance}} />} right>
          <span><IntlText group="place-purchase" id="delivery-fee" /><FontAwesome name="info-circle" /></span>
        </Tooltip>
        <span className="price">{formatPrice(deliveryFeeTotal, true)}</span>
      </div>
      {transactionFee > 0 && 
        <div className="summary-row transaction-fee">
          <span><IntlText group="place-purchase" id="transaction-fee" /></span>
          <span className="price">{formatPrice(transactionFee, true)}</span>
        </div>
      }
      <div className="summary-row">
        <span><IntlText group="place-purchase" id="tax" /></span>
        <span className="price">{formatPrice(tax, true)}</span>
      </div>
      {(customerCashBalanceAdjustment > 0 || customerCashBalanceAdjustment < 0) &&
        <div className={`summary-row balance ${customerCashBalanceAdjustment > 0 ? "negative" : "positive"}`}>
          <span><IntlText group="place-purchase" id="balance" /><Tooltip text={getTranslation({group: "place-purchase", id: "balance-desc"})}><FontAwesome name="info-circle" /></Tooltip></span><span className="price">{formatPrice(customerCashBalanceAdjustment, true)}</span>
        </div>
      }
      <div className="summary-row total">
        <span><IntlText group="place-purchase" id="total" /></span>
        <span className="price">{formatPrice(payment === "CASH" ? chargeInCash : chargeOnCard, true)}</span>
      </div>
      {currency && currency !== "MXN" && 
        <div className="summary-row currency">
          <span><IntlText group="place-purchase" id="currency" /></span>
          <span className="price">{cashChargeInCurrency} {currency} <Tooltip text={getTranslation({group: "place-purchase", id: "applied-rate", variables: {appliedRate: applicableExchangeRate}})}><FontAwesome name="info-circle" /></Tooltip></span>
        </div>
      }
    </div>
  )
}

function PurchasePromotions({promotions}){
  
  if (!(promotions && promotions.length > 0)) return null

  return(
    <div className="summary-promotions">
      {promotions.map((promotion, key) =>
        <div key={key} className='promotion'>
          <i className="fas fa-badge-percent" />
          <IntlValue key={key}>{promotion.label}</IntlValue>
        </div>
      )}
    </div>
  )
}