import { gql } from '@apollo/client'

import { PURCHASE_PRICE_FRAGMENT, PURCHASE_CUSTOMER_FRAGMENT, PURCHASE_ADDRESS_FRAGMENT, PURCHASE_VENDOR_FRAGMENT, PURCHASE_PRODUCT_FRAGMENT, PURCHASE_FEEDBACK_FRAGMENT } from './'

export const PURCHASE_FRAGMENT = gql`
  fragment PURCHASE_FRAGMENT on Purchase {
    id
    slug
    customerNote
    customer{
      ...PURCHASE_CUSTOMER_FRAGMENT
    }
    feedback{
      ...PURCHASE_FEEDBACK_FRAGMENT
    }
    address{
      ...PURCHASE_ADDRESS_FRAGMENT
    }
    vendor{
      ...PURCHASE_VENDOR_FRAGMENT
    }
    products{
      ...PURCHASE_PRODUCT_FRAGMENT
    }
    price{
      ...PURCHASE_PRICE_FRAGMENT
    }
    payment
    paymentStatus
    paymentProblem
    stripePaymentId
    stripePaymentSecret
    currency
    sendUtensils
    status
    preparationTime
    arrivedAtVendor
    estimatedDeliveryTime
    rider{
      name
    }
    timestamps{
      paymentProcessed
      processed
      vendorConfirmed
      assignedToRider
      riderConfirmed
      readyForCollection
      arrivedAtVendor
      collected
      arrivedAtClient
      completed
      deliveryFailed
      cancelled
      rejected
    }
    revisions{
      id

      products{
        ...PURCHASE_PRODUCT_FRAGMENT
      }
      price{
        ...PURCHASE_PRICE_FRAGMENT
      }      
      riderConfirmed
      arrivedAtVendor
      status
      payment
      paymentStatus
      paymentProblem
      conektaPaymentId
      mercadoPagoPaymentId
      currency
      sendUtensils
      preparationTime
      completedIn
      voided
      banknote
      problem
      group
      isMobileApp
      timestamps{
        paymentProcessed
        processed
        vendorConfirmed
        assignedToRider
        riderConfirmed
        readyForCollection
        arrivedAtVendor
        collected
        arrivedAtClient
        unprocessed
        completed
        deliveryFailed
        cancelled
        rejected
      }      
      createdAt
    }    
    type
    createdAt
  }
  ${PURCHASE_CUSTOMER_FRAGMENT}
  ${PURCHASE_ADDRESS_FRAGMENT}
  ${PURCHASE_VENDOR_FRAGMENT}
  ${PURCHASE_PRODUCT_FRAGMENT}
  ${PURCHASE_PRICE_FRAGMENT}
  ${PURCHASE_FEEDBACK_FRAGMENT}
`
