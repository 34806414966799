import { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import FontAwesome from 'react-fontawesome'

import { BUY_PURCHASE_QUOTE } from '../../../graphql'
import { useAuth } from '../../../hooks'
import { toast, sleep } from '../../../helpers'
import { IntlText, Loader } from '../../../components'
import { IntlContext, CartContext } from '../../../contexts'
import { HelpBox, PurchaseTip, PurchaseAlerts, PurchaseButton, PurchaseSummary, PurchaseDeliveryInfo, VerifyNumber } from '../../../views'

import './style.css'

export function PlacePurchase({purchaseQuote, refetch, loading, error}){

  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const { user } = useAuth()
  const [editLoading, toggleEditLoading] = useState(false)
  const [_loading, toggleLoading] = useState(false)
  const { getTranslation } = useContext(IntlContext)
  const { cardData, state: { selectedCard }, emptyCart } = useContext(CartContext)
  const [_buyPurchaseQuote] = useMutation(BUY_PURCHASE_QUOTE, {onError: (error) => {
    refetch()
    throw new Error(error)
  }})      

  async function buyPurchaseQuote(){
    try {
        const { saveCard } = cardData

        await _buyPurchaseQuote({variables: {id: purchaseQuote?.id, saveCard}})

        if (purchaseQuote?.payment === "CARD_STRIPE"){
            await payWithStripe()
            await sleep(2000)
        }

        emptyCart()
        navigate("/orders")                  
    } catch (error) {
      console.log("error", error);
        toast.apolloError(error)
    }
  }

  async function payWithStripe(){
    try {
        if (!stripe || !elements) return console.log("stripe error")

        const { name } = cardData
        const cardElement = elements.getElement(CardElement)
        const payment_method = selectedCard || {
            card: cardElement,
            billing_details: {
                name,
                email: user?.email,
                phone: user?.phone?.phoneCode + user?.phone?.phoneNumber
            }
        }
        const { error } = await stripe.confirmCardPayment(purchaseQuote?.stripeClientSecret, {
            payment_method
        })

        if (error) throw error
    } catch (error) {
        toast.error(error.message)
    }
  }  

  function canPurchase(){
    try {
      if (purchaseQuote?.payment === "CARD_STRIPE"){
        const { name, complete } = cardData

        if (!selectedCard && (!name || !complete)){
          window.scrollTo({ top: document.getElementById("stripe").offsetTop - 80, left: 0, behavior: 'smooth'})
          toast.error(getTranslation({group: "place-purchase", id: "credit-card-error"}))
          return false
        }

      }      
  
      return true      
    } catch (error) {
      console.log("error: ", error);
      return false
    }

  }

  async function purchase(){
    try {
      toggleLoading(true)
      if (!canPurchase()) return toggleLoading(false)    
      
      await buyPurchaseQuote()
      toggleLoading(false)    
    } catch(e) {
      console.log("Create purchase error", e);
      toggleLoading(false)    
    }
  }  

  if (loading) return <div  id="place-purchase-loader"><Loader theme="main" overlay/></div>
  if (error || !purchaseQuote){
    return(
      <div id="place-purchase">
        <div id="purchase-alerts">
          <div className="purchase-alert error">
            <FontAwesome name="exclamation-triangle" />
            <div>
              <p><IntlText group="place-purchase" id="purchase-quote-error" /></p>
              {error?.message &&
                <p><IntlText group="place-purchase" id="purchase-quote-error-message" />{error?.message}</p>
              }
            </div>
          </div>
          <HelpBox />
        </div>
      </div>
    )
  }

  if (purchaseQuote?.forcedCelVerification) return(
    <div id="place-purchase">
      <VerifyNumber success={refetch} />
    </div>
  )    

  return(
    <div id="place-purchase">
      {_loading &&
        <div id="payment-loading-overlay">
          <Loader theme="main" />
          <span className="warning"><IntlText group="place-purchase" id="payment-loading" /></span>
        </div>
      }
      <PurchaseTip purchaseQuote={purchaseQuote} loading={editLoading} toggleLoading={toggleEditLoading} />
      <PurchaseSummary purchaseQuote={purchaseQuote} />
      <PurchaseDeliveryInfo purchaseQuote={purchaseQuote} />
      <PurchaseAlerts purchaseQuote={purchaseQuote} refetch={refetch}>
        <PurchaseButton loading={_loading} disabled={_loading} onClick={purchase} />
      </PurchaseAlerts>
    </div>
  )
}
