import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { ANNOUNCEMENTS_QUERY } from '../../graphql'
import { isValidUrl } from '../../helpers'
import { Loader, Slider } from '../../components'

import './style.css'

export function Announcments() {

    const { loading, data, error } = useQuery(ANNOUNCEMENTS_QUERY)

    if (loading || error) return <Loader theme='main' />
    if (!(data?.announcements?.length > 0)) return null

    return(
        <div className="announcements">           
            <Slider.Slider>
                {data?.announcements?.map((item) => 
                    <Slider.Slide key={item.id}>
                        <Announcment announcement={item} />
                    </Slider.Slide>
                )}
            </Slider.Slider>
        </div>
    )

}

function Announcment({announcement}){

    const navigate = useNavigate()

    function onClick(){
        if (!announcement.link) return null
        if (isValidUrl) {
            window.open(announcement.link, '_blank')
        } else {
            navigate(announcement.link)
        }
    }

    return(
        <picture onClick={onClick}>
            {/* {<source srcSet={getImageInSize(item.cover, "150", "webp")} type="image/webp" />
            <source srcSet={getImageInSize(item.cover, "150", "png")} type="image/png" />} */}
            <img src={announcement.cover} alt={announcement.slug} title={announcement.slug} />
        </picture>     
    )
}