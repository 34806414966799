import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const PAGINATED_QUERIED_VENDORS_LIST = gql`
  query paginatedQueriedVendorList($query: String, $term: VENDOR_LIST_TERM, $mustAcceptCard: Boolean, $highRating: Boolean, $onlyOpen: Boolean, $first: Int, $after: String, $sortBy: VENDOR_LIST_SORT){
    paginatedQueriedVendorList(query: $query, term: $term, mustAcceptCard: $mustAcceptCard, highRating: $highRating, onlyOpen: $onlyOpen, first: $first, after: $after, sortBy: $sortBy){
      edges{
        cursor
        node{
            ...VENDOR_FRAGMENT            
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VENDOR_FRAGMENT}
`
