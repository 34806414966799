import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { IntlText, QueryResults, InfiniteList } from '../../../components'
import { PartnerCard, PartnersLoader } from '../../../views'
import { PAGINATED_QUERIED_VENDORS_LIST } from '../../../graphql'

import './style.css'

export function PartnersSearch({buildQuery, isQuery, clearParams}){

  const { data, loading, error, fetchMore, networkStatus, refetch  } = useQuery(PAGINATED_QUERIED_VENDORS_LIST, {variables: {...buildQuery(), first: 20}, skip: !isQuery(), notifyOnNetworkStatusChange: true})

  useEffect(() => {
    document.addEventListener("visibilitychange", handleAppStateChange);

    return (() => {
      document.removeEventListener("visibilitychange", handleAppStateChange)
    })
     // eslint-disable-next-line
  }, [])  

  async function handleAppStateChange(){
    try {
      const bgts = localStorage.getItem("tomato_go_background_ts")
      localStorage.setItem('tomato_go_background_ts', JSON.stringify(Date.now()))
      if (document.visibilityState === 'visible') {
        if (bgts && !moment(Number(bgts)).isAfter(moment().subtract(4, 'seconds'))){
            await refetch()
        }
      }
    } catch (error) {
      console.log("Refetch error", error)
    }
  }      

  function fetchMoreUpdate(prev, {fetchMoreResult}){
    if (!fetchMoreResult) return prev

    return Object.assign({}, prev, {
      paginatedQueriedVendorList: {
        ...fetchMoreResult.paginatedQueriedVendorList,
        edges: [...prev.paginatedQueriedVendorList.edges, ...fetchMoreResult.paginatedQueriedVendorList.edges]
      }
    })
  }  

  if ((loading && networkStatus !== 4 && networkStatus !== 3 && networkStatus !== 6) || error) return(
    <PartnersLoader />
  )

  if (!data?.paginatedQueriedVendorList?.edges?.length > 0) return (
    <div id="partners-grid" className="no-results">
      <i className="fas fa-search-minus"></i><IntlText group="partners-grid" id="no-results" />
    </div>
  )

  return(
    <div id="partners-search">
      <div>
        {data?.paginatedQueriedVendorList?.edges.length > 0 &&
          <div className="row">
            <QueryResults pageInfo={data?.paginatedQueriedVendorList?.pageInfo} reset={clearParams} />
            <InfiniteList className="partner-cards" fetchMore={() => fetchMore({variables: {first: 20, after: data?.paginatedQueriedVendorList?.pageInfo.endCursor}, updateQuery: fetchMoreUpdate})} loading={loading} disabled={networkStatus === 3} array={data?.paginatedQueriedVendorList?.edges} pageInfo={data?.paginatedQueriedVendorList?.pageInfo}>
              {({node: vendor}, key) =>
                <PartnerCard key={key} vendor={vendor} />
              }
            </InfiniteList>
          </div>
        }
      </div>
    </div>
  )

}
